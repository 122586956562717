.button-social {
  height: 30px;
  opacity: 0.4;
  width: 30px;
  cursor: pointer;
}

.button-social:hover {
  opacity: 1;
}

.card {
  width: 500px;
  background: #1b1c22;
  margin-top: 40px;
  padding: 40px;
}

@media (max-width: 650px) {
  .cards {
    width: 100%;
  }
  .card {
    width: auto;
    padding: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .card-image {
    width: auto;
  }
}
