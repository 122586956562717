.loading-width {
  width: calc(100% - 320px);
  background: #141416;
  margin-top: 50px;
  padding: 20px 0px 150px;
  z-index: 10;
}

@media (max-width: 900px) {
  .loading-width {
    width: calc(100% - 60px);
  }
}
