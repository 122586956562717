@import url("https://fonts.googleapis.com/css?family=Poppins:wght@400;500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

html,
body {
  all: none;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #141416;
  font-family: "Poppins", "Raleway";
  color: #fff;
  -webkit-font-smoothing: antialiased;
  font-weight: 650;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}

/* poppins-900 - latin */
/*@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/poppins-v15-latin-900.eot'); /* IE9 Compat Modes 
  src: local(''),
       url('../fonts/poppins-v15-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 
       url('../fonts/poppins-v15-latin-900.woff2') format('woff2'), /* Super Modern Browsers 
       url('../fonts/poppins-v15-latin-900.woff') format('woff'), /* Modern Browsers 
       url('../fonts/poppins-v15-latin-900.ttf') format('truetype'), /* Safari, Android, iOS 
       url('../fonts/poppins-v15-latin-900.svg#Poppins') format('svg'); /* Legacy iOS 
}
*/

#root {
  height: 100%;
  width: 100%;
}

#app {
  height: 100%;
  width: 100%;
}
.stopwatch-list {
  margin-left: 35px;
}
.sidebar {
  width: 300px;
  height: 100%;
  overflow: hidden;
  position: fixed;
}
@media (max-width: 900px) {
  .sidebar {
    display: none;
  }
}

.tracker {
  padding-left: 100px;
  padding-top: 40px;
  padding-right: 100px;
  margin-left: 300px;
}

@media (max-width: 900px) {
  .tracker {
    margin-left: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.total-panel {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.add-task-button {
  font-size: 16px;
  margin-left: auto;
  cursor: pointer;
}

.mobile-sidebar-burger {
  font-size: 16px;
  margin-left: auto;
}

.mobile-sidebar {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 16px;
  margin-left: auto;
  z-index: 1000;
  overflow: scroll;
}

.mobile-sidebar-background {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 70%;
  z-index: 900;
  overflow: hidden;
}

@media (max-width: 900px) {
  .add-task-button {
    display: none;
  }
  .checklist-blank-button {
    display: inline-block;
  }
}
@media (min-width: 901px) {
  .mobile-sidebar-burger {
    display: none;
  }
}

.totalboard {
  font-size: 24px;
  font-weight: 900;
}

.trac input {
  width: auto;
}

.stopwatch {
  width: 750px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  padding: 20px;
  padding-left: 30px;
  background: rgba(27, 29, 34, 0.5);
  box-shadow: 0px 4px 10px rgba(20, 20, 20, 0.25);
  border-radius: 18px;
  animation-duration: 1s;
  -webkit-transition: height 0.7s;
}

.stopwatch:hover {
  background: rgba(27, 29, 34, 0.7);
  box-shadow: 0px 4px 10px rgba(20, 20, 20, 0.25);
  border-radius: 18px;
  -webkit-transition: height 0.7s;
}

.stopwatch:hover + #additional-section {
  display: inline-block;
}

.project-container {
  display: flex;
  align-items: center;
}
.title-description {
  display: inline;
  width: auto;
}
.title-section {
  display: inline;
}

.title {
  all: unset;
  font-size: 18px;
}
.description-section {
  width: 300px;
}

.description {
  all: unset;
  color: #787f90;
  font-size: 16px;
  width: 100%;
}

.timer-section {
}

button {
  all: unset;
  text-decoration: "none";
  padding: 10px 30px;
  margin: 5px;

  /* Цвет границы */

  opacity: 80%;
  background: #222226;
  border-radius: 12px;
  color: #fff;
  /* Стиль границы */
}

button:hover {
  opacity: 100%;
  text-decoration: "none";
}

.title::placeholder {
  color: grey;
}

.description::placeholder {
  color: #494e58;
}

.calendar {
  height: 300px;
  color: #141416;
}

.total-board {
  display: flex;
  margin-bottom: 60px;
  overflow-x: scroll;
}

.total-board::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.board-card {
  min-width: 250px;
  height: 130px;
  background-color: #1b1d22;
  border-radius: 14px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.image {
  width: 125px;
  height: 130px;
}

.left-arrow,
.right-arrow {
  opacity: 50%;
  margin: 0;
  height: auto;
  width: auto;
  cursor: pointer;
}

.left-arrow:hover,
.right-arrow:hover {
  opacity: 100%;
}

.bbt {
  background: rgba(1, 253, 186, 0.1);
  border: 2px solid #01fdba;
  color: #01fdba;
}

.timer-text-main {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
}
.timer-text-sec {
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
  color: #3d3e42;
  margin-right: 10px;
}

.logo {
  margin-top: 65px;
  text-align: center;
  font-size: 24px;
}
.side-menu {
  /*background-color: #787f90;*/

  margin: 0 auto;
  margin-top: 50px;
  height: auto;
  width: 300px;
  padding: auto;

  display: block;
  align-items: center;
  justify-content: center;
}
.side-menu_element {
  opacity: 70%;
  text-align: center;
  display: flex;
  width: 195px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.side-menu_element:hover {
  opacity: 100%;
  background-color: #272a31;
}

.side-menu_element_text {
  font-size: 19px;
  margin-left: 25px;
}

.control_buttons {
  opacity: 80%;
}

.control_buttons:hover {
  opacity: 100%;
}

.tag {
  margin-top: 10px;
  -webkit-font-smoothing: antialiased;
  background: rgba(145, 189, 255, 0.15);
  border-radius: 7px;
  font-size: 14px;
  font-weight: 1000;
  display: inline-block;
  padding: 3px 15px;
  color: #2176da;
}

.tag-2 {
  margin-top: 10px;
  margin-left: 8px;
  -webkit-font-smoothing: antialiased;
  background: rgba(255, 165, 145, 0.15);
  border-radius: 7px;
  font-size: 14px;
  font-weight: 1000;
  display: inline-block;
  padding: 3px 15px;
  color: #da5921;
}

.additional-section {
  display: none;
}

.content {
  margin-left: 290px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

@media (max-width: 900px) {
  .content {
    margin-left: 0px;
    padding-left: 30px;
  }
}

.icon-color {
  width: 15px;
  height: 15px;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 20px;
  border: 3px solid #b2ff35;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-color-none {
  background-color: none;
  background-color: #da5921;
}

.task {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 20px;
}

.date-picker {
  min-width: 300px;
  vertical-align: top;
}

.date-picker__before {
  min-width: 40px;
  height: 40px;
  background-color: #1b1d22;
  background: url("../assets/arrow-left.svg") no-repeat center, #1b1d22;
  border-radius: 10px 0px 0px 10px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.date-picker__main {
  width: 180px;
  height: 40px;
  background-color: #18191d;
  display: inline-block;
  text-align: center;
  margin: 0;
  vertical-align: top;
  margin-bottom: 20px;
  user-select: none;
}

.date-picker__month {
  margin: 0;
  font-size: 16px;
}

.date-picker__after {
  min-width: 40px;
  height: 40px;
  background: url("../assets/arrow-right.svg") no-repeat center, #1b1d22;
  border-radius: 0px 10px 10px 0px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin-right: 10px;
}

.calendar-icon {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  opacity: 70%;
}

.calendar-icon:hover {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  opacity: 100%;
}

/* medals */

.medals-section {
  height: 30px;
  font-size: 16px;
  color: #151518;
  display: inline-block;
}

.medal {
  width: 25px;
  height: 25px;
  border-radius: 30px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  display: inline-block;
}

.day-medal {
  all: none;
  width: 15px;
  height: 15px;
  margin-bottom: 0px;
  margin-top: 5px;
  border-radius: 8px;

  text-align: center;
  display: inline-block;
}

.day {
  min-width: 20%;
  padding: 10px;
  height: 100px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #1e1c21;
  font-size: 21px;
}

@media (max-width: 700px) {
  .medals-section {
    height: 30px;
    font-size: 16px;
    color: #151518;
    display: block;
    margin-bottom: 10px;
  }
}
@media (max-width: 900px) {
  .day-medal {
    width: 10px;
    height: 10px;
  }
  .day {
    padding: 5px;
    height: auto;
  }
  .medal-grid {
    display: grid;
    grid-auto-rows: 10px;
    grid-template-columns: repeat(auto-fill, 10px);
    gap: 5px;
    margin-bottom: 15px;
  }
}
@media (min-width: 901px) {
  .day-medal {
    width: 15px;
    height: 15px;
  }
  .day {
    min-height: 80px;
    height: auto;
  }
  .medal-grid {
    display: grid;
    grid-auto-rows: 15px;
    grid-template-columns: repeat(auto-fill, 15px);
    gap: 5px;
    margin-bottom: 15px;
  }
}

#calendar {
  max-width: 1200px;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 10px;
  column-gap: 10px;
}

.day-blank {
  opacity: 0;
}

.day-not-active {
  opacity: 50%;
  cursor: auto;
}

.day-padding {
  opacity: 0;
  visibility: none;
  cursor: auto;
}

/* tasks */

.task-card {
  min-height: 160px;
  background-color: #1b1d22;
  box-sizing: border-box;
  padding: 0px 20px 20px 20px;
  position: relative;
}

.task-card__circle {
  width: 60px;
  height: 60px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 40px;
}

.task-card__circle-text {
  color: #1b1d22;
  font-size: 30px;
  margin: auto;
}

.task-card__title {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
}

.task-card__numOfDays {
  text-align: center;
  font-size: 14px;
  opacity: 50%;
}

.tasks-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  row-gap: 15px;
  column-gap: 15px;
  grid-auto-rows: minmax(160px, auto);
  margin-top: 5px;
}

.task-card-more {
  position: absolute;
  margin-top: 12px;
  width: 60px;
  height: 30px;
  /*padding: 0px 0px 10px 0px;*/
  float: right;
  top: 0px;
  right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 50%;
  background: url(./../assets/more.svg) center no-repeat;
}

.task-card-more__more {
  background: url(./../assets/more.svg) center no-repeat;
}
.task-card-more__close {
  background: url(./../assets/close.svg) center no-repeat;
}

.task-card-more:hover {
  opacity: 80%;
}

.task-overlay {
  position: absolute;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: -20px;
  width: 100%;
  height: 100%;
  background: rgba(27, 28, 34, 0.98);
}

.task-card__overlay-buttons {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
  opacity: 80%;
}

.task-card__overlay-buttons:hover {
  opacity: 100%;
}

.task-card__overlay-buttons-delete {
  color: #ff3838;
}

.imgimg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* Single Task Page */

.task-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.big-circle {
  min-width: 70px;
  min-height: 70px;
  border-radius: 70px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-top: -10px;
}

.big-circle__text {
  color: #1b1d22;
  font-size: 30px;
  margin: auto;
}

.title-date {
  margin-left: 20px;
  margin-top: -10px;
}

.task-title {
  font-size: 21px;
}

.input {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
  font-size: 16px;
  text-align: left;
  /*margin-top:10px;*/
  margin-bottom: 10px;
}
.input > .txt-underline {
  border: none;
  outline: none;
}
@media (max-width: 900px) {
  .task-container {
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  .title-date {
    display: block;
    margin-top: 10px;
  }
}

.underline {
  transition: all 0.5s;
  margin-top: 2px;
  display: inline-block;

  /*  left: -100%; */
  left: 0;
  top: 25px;

  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: 50%;
}
.input > .txt-underline:focus + .underline {
  left: 0;
  opacity: 100%;
}

.task-date {
  font-size: 16px;
  opacity: 40%;
}

.task-functional-buttons-container {
  margin-left: 110px;
}

.task-color-changer {
  width: 75px;
  height: 40px;
  background: rgba(196, 196, 196, 0.06);
  border-radius: 7px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.task-color-changer__circle {
  background: #ff6948;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  margin-right: 5px;
}

.task-color-changer__button {
  margin-left: 12px;
  transform: rotate(-90deg);
}

.task-color-changer__functional-button {
  font-size: 16px;
  opacity: 70%;
  margin-top: 15px;
  margin-left: 20px;
  /* margin-left: 20px; */
}
@media (max-width: 900px) {
  .task-functional-buttons-container {
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .task-color-changer__functional-button {
    text-align: center;
    display: block;
    margin-left: 0px;
  }
  .task-color-changer {
    margin-top: 15px;
  }
}

.task-calendar {
  font-size: 14px;
  margin-left: 110px;
}

#calendar-task {
  min-width: 50%;
  max-width: 80%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 10px;
  column-gap: 10px;
  margin-right: 40px;
}

.day-task {
  height: 80px;
  padding: 10px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #1e1c21;
  font-size: 21px;
}

.calendar__circle {
  background: #ff6948;
  width: 12px;
  height: 12px;
  border-radius: 22px;
  margin-top: 5px;
}

.task-calendar-text {
  margin-top: 10px;
  font-size: 21px;
}

/* sidebar */

button:link {
  text-decoration: "none";
}

button:active {
  text-decoration: "none";
}

button:focus {
  text-decoration: "none";
}

.sidebar_buttons {
  text-decoration: "none";
}

.sidebar_buttons:visited {
  text-decoration: "none";
}

.sidebar_buttons:active {
  text-decoration: "none";
}

.link {
  color: #ffffff;
  opacity: 50%;
  padding: 10px;
  display: block;
  width: 150px;
  font-size: 16px;
  letter-spacing: 0.01em;
  margin-top: 10px;
}

.link:hover {
  opacity: 100%;
}

.link-active {
  opacity: 100%;
  background: #272a31;
  border-radius: 12px;
}

.menu-item {
  width: 170px;
  display: block;
  font-size: 16px;
}

.menu-item-down {
  margin-top: auto;
}

.sidebare {
  width: 260px;
  height: 100%;
  min-height: 400px;
  position: fixed;
  background: #1b1c22;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation-name: slideInRight;
}

@media (max-width: 900px) {
  .sidebare {
    display: none;
    /* visibility:hidden;*/
  }
}

.icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 15px;
  margin-left: 15px;
  vertical-align: middle;
}

.sidebar-logo {
  margin-top: 62px;
  letter-spacing: 0.5em;
  font-size: 18px;
  margin-bottom: 120px;
  font-family: "Montserrat";
}

.container-login {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

/* Login */
.log-in {
  /*height: 100%;
  width: 100%;
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;*/
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-height: 500px) {
  .log-in {
    min-height: 500px;
    overflow-y: scroll;
  }
}

.log-in-form {
  height: 370px;
  width: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  margin-top: auto;
}
@media (max-width: 700px) {
  .log-in-form {
    height: 370px;
    width: 350px;
  }
}
@media (max-width: 500px) {
  .log-in-form {
    height: 400px;
    /*width:80%;*/
  }
}

.log-in-button {
  color: #fff;
  background: #1c69ff;
  cursor: pointer;
}

.log-in-text {
  margin-bottom: 10px;
}

.log-in-text__note {
  margin-bottom: 10px;
  margin-top: 60vh;
  position: absolute;
}

.footer {
  width: 100%;
  height: 105px;
  background: url(../assets/footer.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: auto;
}

@media (max-width: 500px) {
  .log-in-text__note {
    width: 250px;
    top: 70%;
    margin-top: 0;
    /*margin-bottom:15%;*/
    /*width:80%;*/
  }
  .footer {
    height: 15%;
  }
}

/*  Custom Checkbox  */

/*.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content:'';
  -webkit-appearance: none;

  border: 2px solid #0079bf;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked + label:before  {
  content:'';
  -webkit-appearance: none;
  background: #0079bf;
  border: 2px solid #0079bf;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked + label:after {
  
  background:  #0079bf;


} */

.dropdown {
  width: 50px;
  height: 50px;
  color: #141416;
  transition: 100ms box-shadow ease;
}

.dropdown-expanded {
  padding: 10px;
}

.dropdown-hidden {
}

/*input[type="checkbox"]:focus {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: 0 0 20px #007a7e;

  width: 14px;
  height: 14px;
  border-radius: 0.15em;
  border: 0.15em solid #007a7e;
  outline: none;
  cursor: pointer;
}*/

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;

  width: 14px;
  height: 14px;
  border-radius: 0.15em;
  margin: auto;
  margin-right: 10px;
  border: 0.15em solid #007a7e;
  outline: none;
  cursor: pointer;
}

input[type="checkbox"].checked {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.4635 7.92401L1.09912 4.56688L2.37855 3.28383L4.46169 5.36335L9.25863 0.566406L10.5399 1.84764L4.4635 7.92401Z' fill='%23151518' stroke='%23151518' stroke-width='0.5'/%3E%3C/svg%3E");

  width: 14px;
  height: 14px;
  border-radius: 0.15em;
  margin: auto;
  margin-right: 10px;
  border: 0.15em solid #007a7e;
  outline: none;
  cursor: pointer;
}

.filter {
  font-size: 16px;
  margin-right: 30px;
  display: inline-block;
}

@media (max-width: 900px) {
  .filter {
    margin-right: 20px;
  }
}

/* Checklist Blank */
.checklist-blank-emoji {
  font-size: 128px;
  display: block;
}
.checklist-blank-text {
  font-size: 16px;
  display: block;
  opacity: 80%;
}

.checklist-blank {
  text-align: center;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 330px;
  position: absolute;
  top: 50%;
  left: calc(50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 900px) {
  .checklist-blank {
    margin-left: 125px;
  }
}

.checklist-blank-button {
  margin-top: 20px;
}
@media (max-width: 500px) {
  .checklist-blank-text {
    font-size: 16px;
  }
  .checklist-blank {
    width: 300px;
  }
}

.text-underline {
  text-decoration: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-line: "underline";
  cursor: pointer;
  text-underline-offset: 4px;
}

.underline::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: white;
  bottom: 0;
  left: 0;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
  animation-duration: 500ms;
}
.toast {
  font-size: 16px;
  text-align: left;
  font-family: "Montserrat-Medium";
  color: #112b53;
}

.modal-small-text {
  text-align: left;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
}

/*loader-animation */
.ring-overlay {
  height: 100%;
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  background: rgba(21, 21, 24, 0.85);
}
.lds-ring {
  display: inline-block;
  position: absolute;
  width: 46px;
  height: 46px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 36px;
  height: 36px;
  margin: 4px;
  border: 4px solid #e8e8e8;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #e8e8e8 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*loader-animation-small */
.ring-overlay-small {
  display: flex;
  width: 0px;
  align-items: center;
  justify-content: center;
  background: rgba(21, 21, 24, 0.85);
}
.lds-ring-small {
  display: inline-block;
  position: relative;
  left: -10px;
  width: 14px;
  height: 14px;
}
.lds-ring-small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  /* margin: 3px;*/
  border: 2px solid #7d7d7d;
  border-radius: 50%;
  animation: lds-ring-small 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #686868 transparent transparent transparent;
}
.lds-ring-small div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-small div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-small div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring-small {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
